import React, { useEffect, useState } from "react";
import { Field, useFormik, Form, Formik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import SVG from "react-inlinesvg";
import { useAlert } from "react-alert";
import { Input } from "../../../../_metronic/_partials/controls";
import { baseUrl } from "../../../../services/config";
import CustomSelect from "../../Student/Form/CustomSelect";
import { Table, Modal, Spinner, Alert } from "react-bootstrap";
import { values } from "lodash-es";
import MaskedInput from "react-text-mask";
import Select from "react-select";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import EditDefinedFee from "../../Student/updateDefinedFee";
import { Toaster, toast } from "react-hot-toast";
import { MdUpdate } from "react-icons/md";
import { PremissionChecker } from "../../../../services/PremissionChecker";
import { Empty } from "antd";
const cnicNumberMask = [
  /[1-9]/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
];

const EditStudent = ({
  studentDetail,
  show,
  reload,
  onUpdateStudent,
  reloader,
  defConce,
  defaulthol,
  defaultVel,
}) => {
  console.log(studentDetail, "studentDetail");
  console.log(defaultVel?.model, "editsdfdsfsd");
  console.log(defConce?.id, "check defConce");

  const STUD_STATUS = [
    {
      id: 1,
      title: "Registered Only",
    },
    {
      id: 2,
      title: "Admission Only",
    },
    {
      id: 3,
      title: "Applied For Admission",
    },
    {
      id: 4,
      title: "Struck Off",
    },
    {
      id: 5,
      title: "Passout",
    },
    {
      id: 6,
      title: "Leaving",
    },
    {
      id: 7,
      title: "Migrate",
    },
  ];

  const [selectedEmpId, setselectedEmpId] = useState(studentDetail.employee_id);
  const [selectedConcessionId, setSelectedConcessionId] = useState(
    studentDetail.concession_id
  );
  const alert = useAlert();
  const authtoken = localStorage.getItem("userToken");
  const user = JSON.parse(localStorage.getItem("userDetails"));
  const [classIDs, setClassIDs] = useState([]);
  const [hostelIDs, setHostelIDs] = useState([]);
  const [vehicleIDs, setVehicleIDs] = useState([]);
  const [concession, setconcession] = useState([]);
  const [loading, setloading] = useState(false);
  const [courses, setcourses] = useState([]);
  const [campuses, setallcampuses] = useState([]);
  const [selectedcampus, setselectedcampus] = useState(studentDetail.campus_id);
  const [selectedEducation, setselectedEdcation] = useState(
    studentDetail.education_type
  );
  const [studentStatus, setstudentStatus] = useState(studentDetail.status);
  const [defaultgender, setdefaultgender] = useState(studentDetail.gender);
  const [inServiceEmpList, setinServiceEmpList] = useState([]);
  const [editablefee, seteditablefee] = useState({});
  const [editmodal, seteditmodal] = useState(false);
  const [allDefinedFees, setallDefinedFees] = useState([]);
  const [deleteloading, setdeleteloading] = useState(false);
  const [deleteModal, setdeleteModal] = useState(false);
  let [anyvehicle, setanyvehicle] = useState("");
  const [deleteablefee, setdeleteablefee] = useState({});
  const [allsections, setallsections] = useState([]);

  const [errorsLis, setErrorsLis] = useState("");
  let [anyconcesstion, setanyconcesstion] = useState("");
  let [anyhostel, setanyhostel] = useState(studentDetail.hostel_id);
  const [empEnable, setempEnable] = useState(
    [2, 4].includes(studentDetail?.concession_id)
  );

  let [errorcreate, seterrorcreate] = useState("Please fill all fields");
  let [isallfields, setisallfields] = useState(false);

  let [previouscampus, setpreviouscampus] = useState(studentDetail.campus_id);
  let [previousclass, setpreviousclass] = useState(
    studentDetail.student_class_id
  );

  const [allsessions, setallsessions] = useState([]);
  const [allconcessions, setallconcessions] = useState([]);
  const [concessionAllowed, setConcessionAllowed] = useState("");
  const [netAmount, setNetAmount] = useState(0);
  const [feetypes, setfeetypes] = useState([]);
  const [amount, setAmount] = useState("");
  const [selectedfeetype, setselectedfeetype] = useState("");
  const [remarks, setremarks] = useState("");
  const [showStuckDate, setShowStuckDate] = useState(
    studentDetail.status == 4 ||
      studentDetail.status == 5 ||
      studentDetail.status == 6 ||
      studentDetail.status == 7
      ? true
      : false
  );
  useEffect(() => {
    // getClassID()
    if (studentDetail.hostel_id == "0") {
      studentDetail.hostel_id = "";
    }
    getVehicleID();
    getHostelID();
    getconcession();
    getcourses();
    getAllCampus();
    onchangeCampus();
    onchangeClass();
    getEmpList();
    getAllSessions();
    getDefinedFees();
    getConcessionTypes();
    setanyconcesstion(studentDetail?.concession_id || "");
  }, []);

  // useEffect(() => {

  // }, [])

  const getEmpList = () => {
    console.log("geeting.....");
    fetch(`${baseUrl}/employees_all_campus`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify({
        job_status_id: "1",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("emp list", data);
        if (data.metadata.success) {
          setinServiceEmpList(data.payload);
          // setTimeout(() => {
          //   setselectedEmpId(studentDetail.employee_id)

          // }, 2000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        setallsessions(data.payload);
        // data.payload.forEach((session, index) => {
        //   if (session.active_academic_year == "1") {
        //     setYearId(session.id);
        //   }
        // });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createEroors = (errors, touched, isSubmitting, values) => {
    setisallfields(false);
    // if (Object.keys(errors).length === 0) {

    //   if (touched) {

    //   } else {
    //     setisallfields(false)
    //     seterrorcreate('')
    //   }

    //   return;
    // }

    if (errors.name && touched.name) {
      seterrorcreate(errors.name);
      setisallfields(true);
      return;
    }

    if (errors.father_name && touched.father_name) {
      seterrorcreate(errors.father_name);
      setisallfields(true);
      return;
    }
    if (errors.year_id && touched.year_id) {
      seterrorcreate(errors.year_id);
      setisallfields(true);
      return;
    }

    if (errors.religion && touched.religion) {
      seterrorcreate(errors.religion);
      setisallfields(true);
      return;
    }

    if (errors.dob && touched.dob) {
      seterrorcreate(errors.dob);
      setisallfields(true);
      return;
    }

    if (errors.gender && touched.gender) {
      seterrorcreate(errors.gender);
      setisallfields(true);
      return;
    }
    if (errors.course_id && touched.course_id) {
      seterrorcreate(errors.course_id);
      setisallfields(true);
      return;
    }
    if (errors.campus_id && touched.campus_id) {
      seterrorcreate(errors.campus_id);
      setisallfields(true);
      return;
    }
    if (!errors.campus_id && touched.campus_id) {
      onchangeCampus(values);
    }

    if (!errors.educationType && touched.educationType) {
      getSectionsListOnTypeChange(
        values.campus_id,
        values.educationType,
        values.class_id
      );
    }

    if (errors.class_id && touched.class_id) {
      seterrorcreate(errors.class_id);
      setisallfields(true);
      return;
    }
    if (errors.concesstion_remarks && touched.concesstion_remarks) {
      seterrorcreate(errors.concesstion_remarks);
      setisallfields(true);
      return;
    }
    if (errors.educationType && touched.educationType) {
      seterrorcreate(errors.educationType);
      setisallfields(true);
      return;
    }
    if (!errors.class_id && touched.class_id) {
      //  getclasssections(values.campus_id, values.class_id)
      onchangeClass(values);
    }
    if (errors.section_id && touched.section_id) {
      seterrorcreate(errors.section_id);
      setisallfields(true);
      return;
    }

    if (errors.address && touched.address) {
      seterrorcreate(errors.address);
      setisallfields(true);
      return;
    }
    if (errors.leaving_date && touched.leaving_date) {
      seterrorcreate(errors.leaving_date);
      setisallfields(true);
      return;
    }
    // if (errors.shift && touched.shift) {
    //     seterrorcreate(errors.shift)
    //     setisallfields(true)
    //     return;
    // }

    if (errors.remarks && touched.remarks) {
      seterrorcreate(errors.remarks);
      setisallfields(true);
      return;
    }

    if (errors.previous_school && touched.previous_school) {
      seterrorcreate(errors.previous_school);
      setisallfields(true);
      return;
    }
    if (errors.class_left && touched.class_left) {
      seterrorcreate(errors.class_left);
      setisallfields(true);
      return;
    }

    // if (errors.cnic_no && touched.cnic_no) {
    //   seterrorcreate(errors.cnic_no);
    //   setisallfields(true);
    //   return;
    // }
    if (errors.father_cnic && touched.father_cnic) {
      seterrorcreate(errors.father_cnic);
      setisallfields(true);
      return;
    }
    if (errors.mobile_no && touched.mobile_no) {
      seterrorcreate(errors.mobile_no);
      setisallfields(true);
      return;
    }
    // if (values.mobile_no == '') {
    //     values.mobile_no = '+92'
    // }

    if (errors.phone && touched.phone) {
      seterrorcreate(errors.phone);
      setisallfields(true);
      return;
    }
    // if (values.phone == '') {
    //     values.phone = '+92'
    // }

    // if (Object.keys(errors).length === 0) {
    //   if (values.name == "") {
    //   } else {
    //     console.log(values,"this is values")
    //     if (isSubmitting) {
    //       let regu = /^(3)([0-9]{9})$/;
    //       if (!values.isformsubmitted) {
    //         values.isformsubmitted = true;
    //         AddStudentHandler(values);
    //       }
    //     }
    //   }
    // }
  };

  const updateStudentLiableFees = async (fee) => {
    // console.log(studentDetail.id);

    let data = {
      student_id: studentDetail.id,
      fee_type_id: fee.fees_type_id,
    };

    // console.log(data);

    await fetch(`${baseUrl}/update-liable-fee`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        // setReceiveBtn(false);

        if (data.metadata) {
          if (data.metadata.success) {
            alert.show(data.payload);
            getDefinedFees();
            // console.log(data.payload);
          }
        }
      })
      .catch((err) => {
        alert.error("Error Occurred");
        console.log(err);
      });
  };

  const StudentRegistrationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),

    father_name: Yup.string().required("Father name is required"),

    dob: Yup.string().notRequired("Date Of Birth required field"),
    stuck_date: showStuckDate
      ? Yup.string()
          .nullable()
          .required("Stuck Of Date is Required")
      : Yup.string()
          .nullable()
          .notRequired("Stuck Of Date is Required"),

    religion: Yup.string()
      .notRequired("Religion is required field")
      .nullable(),
    gender: Yup.string().required("Gender Is Required"),
    // concession_id: Yup.number()
    //   .notRequired('field must be a number'),

    // hostel_id: Yup.number()
    //   .notRequired('field must be a number'),

    // mobile_no: Yup.string()
    //     .required('Mobile Number required field'),

    mobile_no: Yup.string()
      .required("Mobile Number required field")
      .matches(
        /^(3)([0-9]{9})$/,
        "Mobile Number Must start with 3 and must have 13 digits"
      ),

    address: Yup.string().required("Address Is Required"),

    // remarks: Yup.string().notRequired('Remarks required field'),

    // previous_school: Yup.string().notRequired('last School is not required field'),

    // class_left: Yup.string().notRequired('Class Left required field'),

    // leaving_date: Yup.string().notRequired('Leaving Date not required field'),

    // vehicle_id: Yup.number()
    //   .notRequired('required field'),

    shift: Yup.string()
      .notRequired("Shift is not required!")
      .nullable(),

    cnic_no: Yup.string()
      .notRequired()
      .nullable(),
    // .matches(/^[0-9]{5}-[0-9]{7}-[0-9]$/, 'CNIC No must follow the XXXXX-XXXXXXX-X format!'),

    class_id: Yup.string().required("Class Is Required"),

    // // medium:Yup.string()
    // // .ensure()
    // // .required(" required!"),

    // course_id: Yup.string().notRequired('Course Is required'),

    section_id: Yup.string().required("Section Is required"),
    campus_id: Yup.string().required("Campus Is required"),

    year_id: Yup.string().required("Session is required"),

    father_cnic: Yup.string().notRequired("Father Cnic required field"),
    // .matches(/^[0-9]{5}-[0-9]{7}-[0-9]$/, 'CNIC No must follow the XXXXX-XXXXXXX-X format!'),

    phone: Yup.string()
      .notRequired("phone required field")
      .matches(
        /^(?!92)\d+/,
        "Please enter a valid landline number without the country code."
      )
      .nullable(),
    concesstion_remarks:
      anyconcesstion != ""
        ? Yup.string().required("Concession Remarks is required")
        : Yup.string()
            .notRequired()
            .nullable(),
    // .matches(/^(3)([0-9]{9})$/, 'landline Number  Must start with 3 and must have 13 digits'),

    // applied_for: Yup.string()
    //   .required('required field'),
  });
  // const StudentRegistrationSchema = Yup.object().shape({

  //     name: Yup.string()
  //         .required('name is required'),

  //     father_name: Yup.string()
  //         .required('father name is required'),

  //     dob: Yup.string()
  //         .required('required field'),

  //     religion: Yup.string()
  //         .required('required field'),

  //     // concession_id: Yup.number()
  //     //     .notRequired('field must be a number'),

  //     // hostel_id: Yup.number()
  //     //     .notRequired('field must be a number'),

  //     mobile_no: Yup.string()
  //         .required('required field'),

  //     address: Yup.string()
  //         .required('required field'),

  //     remarks: Yup.string()
  //         .required('required field'),

  //     previous_school: Yup.string()
  //         .required('required field'),

  //     class_left: Yup.string()
  //         .required('required field'),

  //     leaving_date: Yup.string()
  //         .required('required field'),

  //     vehicle_id: Yup.number()
  //         .notRequired('required field'),

  //     shift: Yup.string()
  //         // .ensure()
  //         .required("Shift is required!"),

  //     cnic_no: Yup.string()
  //         .required('required field'),

  //     student_class_id: Yup.number()
  //         .required('field must be a number'),
  //     student_section_id: Yup.number()
  //         .required('field must be a number'),

  //     //  medium:Yup.string()
  //     //  .ensure()
  //     //  .required(" required!"),

  //     course_id: Yup.number()
  //         .required('field must be a number'),

  //     father_cnic: Yup.string()
  //         .required('required field'),

  //     phone: Yup.string()
  //         .required('required field'),

  //     //  applied_for: Yup.string()
  //     //    .required('required field'),

  // });
  const getHostelID = async () => {
    await fetch(`${baseUrl}/hostel`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setHostelIDs(data.payload);
      })
      .catch((err) => {
        console.log("hostel", err);
      });
  };
  const getVehicleID = async () => {
    await fetch(`${baseUrl}/vehicle`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setVehicleIDs(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  // console.log(anyconcesstion,"this is any concession")
  const getconcession = async () => {
    await fetch(`${baseUrl}/concession`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setconcession(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };
  const getcourses = async () => {
    await fetch(`${baseUrl}/courses`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setcourses(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };
  const numberChange = (strn) => {
    if (strn) {
      var resultString = strn.replace("92", "");

      return resultString;
    } else {
      return "";
    }
  };
  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallcampuses(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  // console.log(studentDetail.phone)

  // console.log(studentDetail.phone.substring(3))

  // console.log(studentDetail.phone.charAt[1])

  // console.log(studentDetail.phone.charAt(2));

  const initialValues = {
    isformsubmitted: false,
    name: studentDetail.name,
    father_name: studentDetail.father_name,
    dob: studentDetail.dob,
    religion: studentDetail.religion,
    gender: studentDetail.gender,
    concession_id: studentDetail.concession_id,
    hostel_id: studentDetail.hostel_id == 0 ? "" : studentDetail.hostel_id,
    mobile_no: numberChange(studentDetail.mobile_no),
    address: studentDetail.address,
    remarks: studentDetail.remarks,
    previous_school: studentDetail.previous_school,
    class_left: studentDetail.class_left,
    leaving_date: studentDetail.leaving_date,
    joining_date: studentDetail.Joining_date,
    vehicle_id: studentDetail.vehicle_id,
    shift: studentDetail.shift,
    cnic_no: studentDetail.cnic_no,
    // "student_class_id": studentDetail.student_class_id,
    //  "medium": studentDetail.medium,
    course_id: studentDetail.course_id,
    father_cnic: studentDetail.father_cnic,
    phone:
      studentDetail.phone == "92null" ? "" : numberChange(studentDetail.phone),
    // "student_section_id": studentDetail.global_section_id,
    campus_id: studentDetail.campus_id,
    class_id: studentDetail.student_class_id,
    educationType: studentDetail.education_type,
    section_id: studentDetail.global_section_id,
    year_id: studentDetail.session_id,
    stuck_date: studentDetail.struck_off_date,
    concesstion_remarks:
      studentDetail?.concession_remarks == null
        ? ""
        : studentDetail?.concession_remarks,
  };

  if (
    studentDetail.phone?.charAt(0) == "+" &&
    studentDetail.phone?.charAt(1) == "9" &&
    studentDetail.phone?.charAt(2) == "2"
  ) {
    initialValues.phone = studentDetail.phone.substring(3);
  }
  if (
    studentDetail.mobile_no?.charAt(0) == "+" &&
    studentDetail.mobile_no?.charAt(1) == "9" &&
    studentDetail.mobile_no?.charAt(2) == "2"
  ) {
    initialValues.mobile_no = studentDetail.mobile_no.substring(3);
  }

  const onchangeCampus = (e) => {
    let id;
    if (e) {
      if (previouscampus == e.campus_id) {
        return;
      }
      id = e.campus_id;
      setClassIDs([]);

      setpreviouscampus(e.campus_id);
    } else {
      id = studentDetail.campus_id;
    }

    setselectedcampus(id);

    fetch(`${baseUrl}/campusclass/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setClassIDs(data.payload);
        if (e) {
          console.log("e chal raha");
          setallsections([]);
          e.section_id = "";
          e.class_id = "";
        }
      })
      .catch((err) => {
        console.log(err);
        console.log("vehicle", err);
      });
  };
  const getSectionsListOnTypeChange = (campus, id, classID) => {
    if (id == selectedEducation || campus == "") {
      return;
    }

    setselectedEdcation(id);

    fetch(
      `${baseUrl}/class_section/${campus}/${id}${classID ? "/" + classID : ""}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => {
        res.json().then((data) => {
          console.log(data, "section data");
          setallsections(data.payload);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onchangeClass = (e) => {
    // console.log(e.target.value)
    let classid;
    let campusid;
    let educationType;
    if (e) {
      if (previousclass == e.class_id) {
        return;
      }
      campusid = selectedcampus;
      classid = e.class_id;
      educationType = selectedEducation;
      setpreviousclass(e.class_id);
    } else {
      classid = studentDetail.student_class_id;
      campusid = studentDetail.campus_id;
      educationType = studentDetail.education_type;
    }

    fetch(`${baseUrl}/class_section/${campusid}/${educationType}/${classid}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("sections", data.payload);
        setallsections(data.payload);
      })
      .catch((err) => {
        console.log("campuses", err);
      });
  };

  const AddStudentHandler = async (values) => {
    // setloading(true);
    console.log(values, "ya values");
    console.log("han bhai ho rh ah submit?");

    setisallfields(false);
    const fdata = {
      name: values.name,
      father_name: values.father_name,
      cnic_no: values.cnic_no,
      dob: values.dob,
      religion: values.religion,
      gender: values.gender,
      campus_id: values.campus_id,
      education_type: values.educationType,
      concession_id: anyconcesstion,
      hostel_id: anyhostel == "0" ? null : anyhostel,
      mobile_no: "92" + values.mobile_no,
      address: values.address,
      remarks: values.remarks,
      previous_school: values.previous_school,
      class_left: values.class_left,
      leaving_date: values.leaving_date,
      vehicle_id: anyvehicle != "" ? anyvehicle : studentDetail.vehicle_id,
      shift: values.shift,
      student_class_id: values.class_id,
      // medium: values.medium,
      course_id: values.course_id,
      father_cnic: values.father_cnic,
      phone: "92" + values.phone,
      year_id: values.year_id,
      global_section_id: values.section_id,
      employee_id: selectedEmpId,
      Joining_date: values.joining_date,
      status: studentStatus,
      struck_off_date: showStuckDate ? values.stuck_date : "",
      concession_remarks:
        anyconcesstion != "" || anyconcesstion != null
          ? values.concesstion_remarks
          : null,
      // admission_id: 123,
      // applied_for: values.applied_for,

      // qualification: values.qualification,
    };
    if (fdata.hostel_id == "0") {
      fdata["hostel_id"] = "";
    }
    console.log(fdata);

    fetch(`${baseUrl}/student/${studentDetail.id}?_method=PUT`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(fdata),
    })
      .then((res) => {
        res.json().then((data) => {
          console.log(data);
          if (data.metadata.success) {
            setloading(false);
            onUpdateStudent("", data.payload.campus_id);
            // show(false);
            // alert.success("Updated Successfully",{
            //   position:'top left'
            // });
            toast.success("Updated Successfully");

            reloader(studentDetail.global_section_id);
          } else {
            console.log(toString.call(data.metadata.message), "this is");
            if (toString.call(data.metadata.message) == "[object Object]") {
              Object.values(data.metadata.message).map((msg) => {
                toast.error(msg);
              });
            } else {
              toast.error(data.metadata.message);
            }

            setloading(false);
            console.log(data);
          }
        });
      })
      .catch((err) => {
        setloading(false);
        // alert.error(err);
        toast.success(err);

        console.log(err);
      });
  };

  if (studentDetail.gender == "Male") {
    let data = {
      value: "Male",
      label: "Male",
    };
  }

  let array = ["Male", "Female", "Other"];

  const Genderoptions = [
    // { value: 'Male', label: 'Male' },
    // { value: 'Female', label: 'Female' },
    // { value: 'Other', label: 'Other' },
  ];
  array.map((item, index) => {
    let checkselect = false;
    if (defaultgender == item) {
      checkselect = true;
    }

    Genderoptions[index] = { value: item, label: item, selected: checkselect };
  });

  const concessionoption = [{ value: "", label: "Select" }];
  concession.map((item, index) => {
    concessionoption[index + 1] = { value: item.id, label: item.title };
  });
  console.log(concessionoption, "this is");
  const hosteloptions = [{ value: "", label: "Select" }];
  hostelIDs.map((item, index) => {
    hosteloptions[index + 1] = { value: item.id, label: item.name };
  });
  const vehicleoption = [];
  vehicleIDs.map((item, index) => {
    vehicleoption[index] = { value: item.id, label: item.model };
  });
  const coursesoptions = [];
  courses.map((item, index) => {
    coursesoptions[index] = { value: item.id, label: item.name };
  });
  const shiftoption = [
    { value: "1st", label: "1st" },
    { value: "2nd", label: "2nd" },
  ];

  const empOptions = [];
  empOptions[0] = { value: "", label: "Select..." };

  inServiceEmpList.map((item, index) => {
    empOptions[index + 1] = {
      value: item.id,
      label: `${
        item.full_name.length > 15
          ? item.full_name.substr(0, 10)
          : item.full_name
      }-${item.emp_code}`,
    };
  });

  const onChangeGetEmployeList = (event) => {
    setselectedEmpId(event.value);
  };

  const deleteFee = () => {
    setdeleteloading(true);

    fetch(`${baseUrl}/student_liable_fee/${deleteablefee.id}}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            alert.show(data.payload);
            getDefinedFees();
            setdeleteloading(false);
            setdeleteModal(false);
            getfeeTypes(studentDetail.session_id, allDefinedFees);
          } else {
            // alert.show(data.payload);
            setdeleteloading(false);
            setdeleteModal(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        console.log("err", err);
        setdeleteloading(false);
        setdeleteModal(false);
      });
  };

  const getDefinedFees = () => {
    fetch(`${baseUrl}/student_all_liable_fees/${studentDetail.id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            getfeeTypes(studentDetail.session_id, data.payload);
            setallDefinedFees([...data.payload]);
            console.log(data.payload, "all feeswes of student");
          } else {
            // alert.show(
            //   "Something Went Wrong While Fetching Student Defined Fees"
            // );
          }
        }
      })
      .catch((err) => {
        console.log(err);
        console.log("err", err);
        // alert.show("Something Went Wrong While Fetching Student Defined Fees");
      });
  };

  //hitting concession apis
  const getConcessionTypes = () => {
    fetch(`${baseUrl}/concession`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            setallconcessions(data.payload);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        console.log("err", err);
      });
  };

  const getRespctiveFee = (id) => {
    setselectedfeetype(id);
    let data = {
      campus_id: studentDetail.campus_id,
      student_class_id: studentDetail.student_class_id,
      fee_type_id: id,
      concession_id: selectedConcessionId,
      year_id: studentDetail.session_id,
      education_type: studentDetail.education_type,
    };
    console.log(data);
    fetch(`${baseUrl}/get-student-concession-amount`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            setNetAmount(data.payload.net_amount);
            setAmount(data.payload.amount);
            data.payload?.concession_allowed != null
              ? setConcessionAllowed(data.payload.concession_allowed)
              : setConcessionAllowed("No Defined");
          } else {
            console.log(data);
            // setloading(false)
            // show(false)
            alert.error("Student Fee Not Found");
          }
        }
      })
      .catch((err) => {
        alert.show("Something Went Wrong");
        console.log("err", err);
      });
  };

  const getfeeTypes = (session_id, arr2) => {
    fetch(
      `${baseUrl}/classfeetypes/${studentDetail.campus_id}/${studentDetail.student_class_id}/${session_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        let checkboxes = data.payload;
        data.payload.map((item, index) => {
          item["disabled"] = false;
          checkboxes[index] = item;
        });
        handleFeeTypeDisable(checkboxes, arr2);
      })
      .catch((err) => {
        console.log(err);
        console.log("err", err);
      });
  };

  const handleFeeTypeDisable = (arr, arr2) => {
    let array = [...arr];
    let array2 = [...arr2];
    let newArray = [];

    arr2.forEach((element, index) => {
      arr.forEach((item, i) => {
        if (element.fees_type_id == item.id) {
          arr[i].disabled = true;
        }
      });
    });
    setfeetypes(arr);
  };

  const feeDefine = () => {
    setisallfields(false);

    let res = checkerror();
    if (!res) {
      return alert.error("Please Check All Fields");
    }

    setloading(true);
    let data = {
      student_id: studentDetail.id,
      fees_type_id: selectedfeetype,
      amount: parseInt(amount),
      concession_amount: parseInt(netAmount - amount),
      remarks: remarks,
    };

    console.log(data);
    fetch(`${baseUrl}/student_liable_fee`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setloading(false);
        if (data.metadata) {
          if (data.metadata.success) {
            setloading(false);
            // show(false)
            alert.success("Fee Added Successfully");
            setConcessionAllowed("");
            setAmount("");
            setNetAmount(0);
            getDefinedFees();
          } else {
            setloading(false);
            // show(false)
            alert.error("Fee Not Added");
          }
        } else {
          alert.error("Fee Not Added");
        }
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
        console.log("err", err);
        alert.show("Error Occured");
      });
  };

  const checkerror = () => {
    if (!selectedfeetype) {
      setisallfields(true);
      seterrorcreate("Please Add Amount");
      return false;
    }
    if (amount == "") {
      setisallfields(true);
      seterrorcreate("Please Add Fee");
      return false;
    }
    setisallfields(false);
    return true;
  };
  // const loginAccount = JSON.parse(localStorage.getItem("userDetails")).role[0];

  return (
    <div>
      <Toaster />

      {/* <div className="generate text-center">Enter Student Detail and Submit</div> */}
      {loading ? (
        <b>
          Student Updating Please wait <span className="spinner-border"></span>
        </b>
      ) : (
        <div className="container-fluid">
          <div
            style={{
              marginTop: "0px",
              border: "0px",
              marginBottom: "0px",
              paddingBottom: "0px",
            }}
            className="card card-custom"
          >
            {/* <div className="card-header" >
                                <div className="card-title">
                                    <h3 className="card-label">
                                        New Student |
                                    </h3>
                                    <small>Enter Student details and submit</small>
                                </div>
                            </div> */}
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div
                    className="login-form login-signin"
                    style={{ display: "block" }}
                  >
                    <Formik
                      enableReinitialize={true}
                      initialValues={initialValues}
                      validationSchema={StudentRegistrationSchema}
                      onSubmit={(values) => {
                        AddStudentHandler(values);
                      }}
                    >
                      {(props) => {
                        const {
                          handleSubmit,
                          errors,
                          touched,
                          values,
                          isSubmitting,
                          isValid,
                          handleChange,
                          handleBlur,
                        } = props;

                        setErrorsLis(errors);
                        createEroors(errors, touched, isSubmitting, values);

                        return (
                          <>
                            {/* {console.log(errors)}
                          {console.log(touched)} */}
                            <div className="overlay overlay-block cursor-default">
                              {/* {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )} */}

                              <Form
                                onSubmit={handleSubmit}
                                className="form form-label-right"
                              >
                                <div className="form-group row">
                                  <div className="col-lg-4">
                                    <label>
                                      Name{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <Field
                                      disabled={
                                        !PremissionChecker(
                                          user,
                                          "edit_student.name"
                                        )
                                      }
                                      name="name"
                                      component={Input}
                                      placeholder="First Name"
                                      //  label="Name"
                                      maxLength={120}
                                      // customFeedbackLabel='Enter Your Full Name'
                                    />
                                  </div>
                                  <div className="col-lg-4">
                                    <label>
                                      Father Name{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <Field
                                      disabled={
                                        !PremissionChecker(
                                          user,
                                          "edit_student.father_name"
                                        )
                                      }
                                      name="father_name"
                                      component={Input}
                                      placeholder="Enter Father Name"
                                      //label="Father Name"
                                      maxLength={120}
                                    />
                                  </div>

                                  <div className="col-lg-4">
                                    <label>
                                      Select Session{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <select
                                      disabled={
                                        !PremissionChecker(
                                          user,
                                          "edit_student.session_selection"
                                        )
                                      }
                                      class="form-select form-control"
                                      name="year_id"
                                      value={values.year_id}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      style={{ backgroundColor: "white" }}
                                      aria-label="Default select example"
                                    >
                                      <option value="">Select Session</option>
                                      {allsessions.map((item) => (
                                        <option value={item.id}>
                                          {item.year}
                                        </option>
                                      ))}
                                    </select>
                                    {errors.year_id && touched.year_id && (
                                      <div
                                        style={{ color: "#f87d8a" }}
                                        className="input-feedback"
                                      >
                                        {errors.year_id}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <div className="col-lg-4">
                                    <label>
                                      Date of Birth{" "}
                                      {/* <span style={{ color: "red" }}>*</span> */}
                                    </label>
                                    <Field
                                      disabled={
                                        !PremissionChecker(
                                          user,
                                          "edit_student.dob"
                                        )
                                      }
                                      name="dob"
                                      component={Input}
                                      type="date"
                                      max="9999-11-30"
                                      // label="Date of Birth"
                                    />
                                  </div>
                                  <div className="col-lg-4">
                                    <label>Religion </label>
                                    <Field
                                      disabled={
                                        !PremissionChecker(
                                          user,
                                          "edit_student.religion"
                                        )
                                      }
                                      name="religion"
                                      component={Input}
                                      placeholder="Enter Your Religion"
                                      maxLength={120}
                                      // label="Religion"
                                    />
                                  </div>
                                  <div className="col-lg-4">
                                    <label>
                                      Gender{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <select
                                      disabled={
                                        !PremissionChecker(
                                          user,
                                          "edit_student.gender"
                                        )
                                      }
                                      class="form-select form-control"
                                      name="gender"
                                      value={values.gender}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      style={{ backgroundColor: "white" }}
                                      aria-label="Default select example"
                                    >
                                      <option selected value="">
                                        Select Gender
                                      </option>
                                      <option value="Male">Male</option>
                                      <option value="Female">Female</option>
                                      <option value="Other">Other</option>
                                    </select>
                                    {errors.gender && touched.gender && (
                                      <div
                                        style={{ color: "#f87d8a" }}
                                        className="input-feedback"
                                      >
                                        {errors.gender}
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <div className="col-lg-4">
                                    <label>Concession </label>
                                    <Select
                                      isDisabled={
                                        !PremissionChecker(
                                          user,
                                          "edit_student.concession_selection"
                                        )
                                      }
                                      defaultValue={{
                                        label: defConce?.title,
                                        value: defConce?.id,
                                      }}
                                      // value={anyconcesstion}
                                      className="input"
                                      onChange={(e) => {
                                        if (e.value == "2" || e.value == "4") {
                                          setempEnable(true);
                                        } else {
                                          setempEnable(false);
                                        }
                                        setanyconcesstion(e.value);
                                      }}
                                      placeholder="Select Concession"
                                      options={concessionoption}
                                    />
                                  </div>

                                  <div className="col-lg-4">
                                    <label>Enter Concession Remarks </label>

                                    <Field
                                      disabled={
                                        !PremissionChecker(
                                          user,
                                          "edit_student.concession_remarks"
                                        ) || anyconcesstion == ""
                                      }
                                      name="concesstion_remarks"
                                      component={Input}
                                      placeholder="Concesstion Remarks"
                                      required
                                      type="text"
                                    />
                                  </div>

                                  <div className="col-lg-4">
                                    <label>Hostel </label>

                                    <Select
                                      isDisabled={
                                        !PremissionChecker(
                                          user,
                                          "edit_student.select_hostel"
                                        )
                                      }
                                      defaultValue={{
                                        label: defaulthol?.name,
                                        value: defaulthol?.id,
                                      }}
                                      className="input"
                                      onChange={(e) => setanyhostel(e.value)}
                                      placeholder="Select Hostel"
                                      options={hosteloptions}
                                    />
                                  </div>

                                  {/* select campus  */}
                                  <div className="col-lg-4 mt-9">
                                    <label>Father CNIC </label>
                                    <Field
                                      name="father_cnic"
                                      render={({ field }) => (
                                        <MaskedInput
                                          {...field}
                                          disabled={
                                            !PremissionChecker(
                                              user,
                                              "edit_student.father_cnic"
                                            )
                                          }
                                          mask={cnicNumberMask}
                                          id="father_cnic"
                                          placeholder="Enter Your Father CNIC"
                                          type="text"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          // className='is-invalid form-control'
                                          className={
                                            errors.father_cnic &&
                                            touched.father_cnic
                                              ? "is-invalid form-control"
                                              : "form-control"
                                          }
                                        />
                                      )}
                                    />
                                  </div>

                                  <div className="col-lg-4 mt-9">
                                    <label>
                                      Select Campus{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <select
                                      disabled={
                                        !PremissionChecker(
                                          user,
                                          "edit_student.select_campus"
                                        )
                                      }
                                      className={"form-select form-control"}
                                      name="campus_id"
                                      value={values.campus_id}
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                      onBlur={handleBlur}
                                      style={{ backgroundColor: "white" }}
                                      aria-label="Default select example"
                                    >
                                      <option selected disabled value="">
                                        Select Campus
                                      </option>
                                      {campuses.map((item) => (
                                        <option value={item.id}>
                                          {item.name}
                                        </option>
                                      ))}
                                    </select>
                                    {errors.campus_id && touched.campus_id && (
                                      <div
                                        style={{ color: "#f87d8a" }}
                                        className="input-feedback"
                                      >
                                        {errors.campus_id}
                                      </div>
                                    )}
                                  </div>

                                  <div className="col-lg-4 mt-9">
                                    <label>
                                      {" "}
                                      Select Class{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <select
                                      disabled={
                                        !PremissionChecker(
                                          user,
                                          "edit_student.select_class"
                                        )
                                      }
                                      className={"form-select form-control"}
                                      name="class_id"
                                      value={values.class_id}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      style={{ backgroundColor: "white" }}
                                      aria-label="Default select example"
                                    >
                                      <option selected value="">
                                        Select Class
                                      </option>
                                      {classIDs.map((item) => (
                                        <option value={item.id}>
                                          {item.name}
                                        </option>
                                      ))}
                                    </select>
                                    {errors.class_id && touched.class_id && (
                                      <div
                                        style={{ color: "#f87d8a" }}
                                        className="input-feedback"
                                      >
                                        {errors.class_id}
                                      </div>
                                    )}
                                  </div>

                                  <div className="col-lg-4 mt-9">
                                    <label>
                                      Select Education Type
                                      <span style={{ color: "red" }}>*</span>
                                    </label>

                                    <select
                                      disabled={
                                        !PremissionChecker(
                                          user,
                                          "edit_student.select_education_type"
                                        )
                                      }
                                      className={"form-select form-control"}
                                      name="educationType"
                                      value={values.educationType}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      style={{ backgroundColor: "white" }}
                                      aria-label="Default select example"
                                    >
                                      <option value="">
                                        {" "}
                                        Select Education Type
                                      </option>
                                      <option selected value={1}>
                                        Education
                                      </option>
                                      <option value={2}>Hifz</option>
                                    </select>
                                    {errors.educationType &&
                                      touched.educationType && (
                                        <div
                                          style={{ color: "#f87d8a" }}
                                          className="input-feedback"
                                        >
                                          {errors.educationType}
                                        </div>
                                      )}
                                  </div>

                                  <div className="col-lg-4 mt-9">
                                    <label>
                                      {" "}
                                      Select Section{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <select
                                      disabled={
                                        !PremissionChecker(
                                          user,
                                          "edit_student.select_section"
                                        )
                                      }
                                      className={"form-select form-control"}
                                      name="section_id"
                                      value={values.section_id}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      style={{ backgroundColor: "white" }}
                                      aria-label="Default select example"
                                    >
                                      <option selected value="">
                                        Select Section
                                      </option>
                                      {allsections.map((item) => (
                                        <option value={item.id}>
                                          {item.name}
                                        </option>
                                      ))}
                                    </select>
                                    {errors.section_id && touched.section_id && (
                                      <div
                                        style={{ color: "#f87d8a" }}
                                        className="input-feedback"
                                      >
                                        {errors.section_id}
                                      </div>
                                    )}
                                  </div>

                                  <div className="col-lg-4 mt-9">
                                    <label>
                                      Address{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>

                                    <Field
                                      disabled={
                                        !PremissionChecker(
                                          user,
                                          "edit_student.address"
                                        )
                                      }
                                      name="address"
                                      maxLength={120}
                                      component={Input}
                                      placeholder="Enter Your Address"
                                    />
                                  </div>

                                  <div className="col-lg-4 mt-9">
                                    <label>CNIC/B-Form </label>

                                    <Field
                                      name="cnic_no"
                                      render={({ field }) => (
                                        <MaskedInput
                                          {...field}
                                          disabled={
                                            !PremissionChecker(
                                              user,
                                              "edit_student.student_cnic"
                                            )
                                          }
                                          mask={cnicNumberMask}
                                          id="cnic_no"
                                          placeholder="Enter Your CNIC / B-Form No"
                                          type="text"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          className={
                                            errors.cnic_no && touched.cnic_no
                                              ? "is-invalid form-control"
                                              : "form-control"
                                          }
                                        />
                                      )}
                                    />
                                  </div>
                                  <div className="col-lg-4 mt-9">
                                    <label>Select Employee </label>
                                    <div className="input">
                                      <CustomSelect
                                        disabled={
                                          !PremissionChecker(
                                            user,
                                            "edit_student.select_employee"
                                          ) || !empEnable
                                        }
                                        // isDisabled={empEnable ? false : true}
                                        className="input"
                                        value={selectedEmpId}
                                        onChange={(evt) => {
                                          onChangeGetEmployeList(evt);
                                        }}
                                        placeholder="Select Employee"
                                        options={empOptions}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-lg-4 mt-9">
                                    <label>Leaving Date </label>

                                    <Field
                                      disabled={
                                        !PremissionChecker(
                                          user,
                                          "edit_student.leaving_date"
                                        )
                                      }
                                      name="leaving_date"
                                      component={Input}
                                      type="date"
                                      max="9999-11-30"
                                    />
                                  </div>
                                  <div className="col-lg-4 mt-9">
                                    <label>Vehicle </label>

                                    <Select
                                      isDisabled={
                                        !PremissionChecker(
                                          user,
                                          "edit_student.select_vehicle"
                                        )
                                      }
                                      defaultValue={{
                                        label: defaultVel?.model,
                                        value: defaultVel?.id,
                                      }}
                                      className="input"
                                      onChange={(e) => setanyvehicle(e.value)}
                                      placeholder="Select Vehicle"
                                      options={vehicleoption}
                                    />
                                  </div>

                                  <div className="col-lg-4 mt-9">
                                    <label>Shift </label>
                                    <select
                                      disabled={
                                        !PremissionChecker(
                                          user,
                                          "edit_student.select_shift"
                                        )
                                      }
                                      className={"form-select form-control"}
                                      name="shift"
                                      value={values.shift}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      aria-label="Default select example"
                                    >
                                      {shiftoption.map((item) => (
                                        <option value={item.value}>
                                          {item.label}
                                        </option>
                                      ))}
                                    </select>
                                    {errors.shift && touched.shift && (
                                      <div
                                        style={{ color: "#f87d8a" }}
                                        className="input-feedback"
                                      >
                                        {errors.shift}
                                      </div>
                                    )}
                                  </div>

                                  <div className="col-lg-4 mt-9">
                                    <label>
                                      Remarks{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>

                                    <Field
                                      maxLength={120}
                                      name="remarks"
                                      component={Input}
                                      placeholder="remarks"
                                      //  label="remarks"
                                    />
                                  </div>
                                  <div className="col-lg-4 mt-9">
                                    <label>
                                      Previous School{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>

                                    <Field
                                      disabled={
                                        !PremissionChecker(
                                          user,
                                          "edit_student.previous_school"
                                        )
                                      }
                                      maxLength={120}
                                      name="previous_school"
                                      component={Input}
                                      placeholder="Enter Student previous School"
                                    />
                                  </div>
                                  <div className="col-lg-4 mt-9">
                                    <label>
                                      Class Left{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>

                                    <Field
                                      disabled={
                                        !PremissionChecker(
                                          user,
                                          "edit_student.class_left"
                                        )
                                      }
                                      maxLength={120}
                                      name="class_left"
                                      component={Input}
                                      placeholder="Enter Student Class left"
                                    />
                                  </div>

                                  {showStuckDate ? (
                                    <div className="col-md-4 mt-9">
                                      <label>Stuck Of Date</label>
                                      <Field
                                        disabled={
                                          !PremissionChecker(
                                            user,
                                            "edit_student.stuck_of_date"
                                          )
                                        }
                                        name="stuck_date"
                                        component={Input}
                                        type="date"
                                        max="9999-11-30"
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                <div className="form-group row">
                                  <div className="col-lg-4">
                                    <label>Course </label>
                                    <select
                                      disabled={
                                        !PremissionChecker(
                                          user,
                                          "edit_student.select_course"
                                        )
                                      }
                                      class="form-select form-control"
                                      name="course_id"
                                      value={values.course_id}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      aria-label="Default select example"
                                    >
                                      <option selected value="">
                                        Select Course
                                      </option>
                                      {courses.map((item) => (
                                        <option value={item.id}>
                                          {item.name}
                                        </option>
                                      ))}
                                    </select>
                                    {errors.course_id && touched.course_id && (
                                      <div
                                        style={{ color: "#f87d8a" }}
                                        className="input-feedback"
                                      >
                                        {errors.course_id}
                                      </div>
                                    )}
                                  </div>

                                  <div className="col-lg-4">
                                    <label>Student Status</label>
                                    <select
                                      disabled={
                                        !PremissionChecker(
                                          user,
                                          "edit_student.student_status"
                                        )
                                      }
                                      className={"form-select form-control"}
                                      value={studentStatus}
                                      onChange={(e) => {
                                        let value = e.target.value;
                                        if (
                                          value == 4 ||
                                          value == 5 ||
                                          value == 6 ||
                                          value == 7
                                        ) {
                                          setShowStuckDate(true);
                                        } else {
                                          setShowStuckDate(false);
                                        }
                                        setstudentStatus(e.target.value);
                                      }}
                                      aria-label="Default select example"
                                    >
                                      <option selected value="">
                                        Select Status
                                      </option>
                                      {STUD_STATUS.map((item) => (
                                        <option value={item.id}>
                                          {item.title}
                                        </option>
                                      ))}
                                    </select>
                                  </div>

                                  <div className="col-md-4">
                                    <label>Joining date</label>
                                    <Field
                                      disabled={
                                        !PremissionChecker(
                                          user,
                                          "edit_student.joining_date"
                                        )
                                      }
                                      name="joining_date"
                                      component={Input}
                                      type="date"
                                      max="9999-11-30"
                                    />
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <div className="col-lg-6">
                                    <label>
                                      Mobile Number{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>

                                    <Field
                                      disabled={
                                        !PremissionChecker(
                                          user,
                                          "edit_student.mobile_number"
                                        )
                                      }
                                      maxLength={20}
                                      pattern="^(\+92)(3)([0-9]{9})$"
                                      required
                                      name="mobile_no"
                                      component={Input}
                                      placeholder="Enter Your Smart Phone Number"
                                    />
                                  </div>

                                  <div className="col-lg-6">
                                    <label>Landline Number </label>
                                    <Field
                                      maxLength={20}
                                      pattern="^(?!92)\d+"
                                      required
                                      name="phone"
                                      component={Input}
                                      placeholder="Enter Your Phone/LandLine No"
                                    />
                                  </div>
                                </div>
                              </Form>
                            </div>

                            <div>
                              {/* /////this is for define fee section */}
                              <div className="">
                                <div className="row d-flex justify-content-center ">
                                  <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                    <div>
                                      {PremissionChecker(
                                        user,
                                        "edit_student.all_defined_fees"
                                      ) ? (
                                        <div className="row justify-content-between text-left">
                                          <Table responsive>
                                            <thead>
                                              <tr id="tbl">
                                                <th>Fee Name</th>
                                                <th>Total Amount</th>
                                                <th>Payable Amount</th>
                                                <th>Concession</th>
                                                <th>Remarks</th>
                                                <th>Edit</th>
                                                <th>Delete</th>
                                                <th>Update</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {allDefinedFees.map((fees, i) => {
                                                return (
                                                  <tr
                                                    key={fees.id}
                                                    className="addstudentList"
                                                  >
                                                    <td>
                                                      {fees?.fee_type?.name}
                                                    </td>
                                                    <td>
                                                      {parseInt(fees.amount) +
                                                        parseInt(
                                                          fees.concession_amount
                                                        )}
                                                    </td>
                                                    <td>{fees.amount}</td>
                                                    <td>
                                                      {fees.concession_amount}
                                                    </td>
                                                    {/* <td  >remark?</td> */}
                                                    <td>
                                                      {fees?.remarks?.length >
                                                      35
                                                        ? fees?.remarks.slice(
                                                            0,
                                                            35
                                                          ) + ".."
                                                        : fees?.remarks}
                                                    </td>
                                                    <td>
                                                      {PremissionChecker(
                                                        user,
                                                        "edit_student.edit_define_fee"
                                                      ) ? (
                                                        <a
                                                          className={
                                                            PremissionChecker(
                                                              user,
                                                              "edit_student.edit_define_fee"
                                                            )
                                                              ? "btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                                              : "btn btn-icon btn-light btn-hover-primary btn-sm mx-3 disabledSvg"
                                                          }
                                                          onClick={() => {
                                                            seteditablefee(
                                                              fees
                                                            );
                                                            seteditmodal(true);
                                                          }}
                                                        >
                                                          <span className="svg-icon svg-icon-md svg-icon-primary ">
                                                            <SVG
                                                              src={toAbsoluteUrl(
                                                                "/media/svg/icons/Communication/Write.svg"
                                                              )}
                                                            />
                                                          </span>
                                                        </a>
                                                      ) : null}
                                                    </td>
                                                    <td>
                                                      {PremissionChecker(
                                                        user,
                                                        "edit_student.delete_define_fee"
                                                      ) ? (
                                                        <a
                                                          onClick={() => {
                                                            setdeleteablefee(
                                                              fees
                                                            );
                                                            setdeleteModal(
                                                              true
                                                            );
                                                          }}
                                                        >
                                                          <span className="svg-icon svg-icon-md svg-icon-danger">
                                                            <SVG
                                                              src={toAbsoluteUrl(
                                                                "/media/svg/icons/Communication/Delete-user.svg"
                                                              )}
                                                            />
                                                          </span>
                                                        </a>
                                                      ) : null}
                                                    </td>

                                                    <td>
                                                      {PremissionChecker(
                                                        user,
                                                        "edit_student.update_define_fee"
                                                      ) ? (
                                                        <a
                                                          className={
                                                            PremissionChecker(
                                                              user,
                                                              "edit_student.update_define_fee"
                                                            )
                                                              ? "btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                                              : "btn btn-icon btn-light btn-hover-primary btn-sm mx-3 disabledSvg"
                                                          }
                                                          onClick={() => {
                                                            updateStudentLiableFees(
                                                              fees
                                                            );
                                                          }}
                                                        >
                                                          <span className="svg-icon svg-icon-md svg-icon-success">
                                                            <SVG
                                                              src={toAbsoluteUrl(
                                                                "/media/svg/icons/Files/Download.svg"
                                                              )}
                                                            />
                                                          </span>
                                                        </a>
                                                      ) : null}
                                                    </td>
                                                  </tr>
                                                );
                                              })}
                                            </tbody>
                                          </Table>
                                        </div>
                                      ) : (
                                        <Empty
                                          description={
                                            "You dont have Permission to view Fee defined list"
                                          }
                                        ></Empty>
                                      )}
                                      <div className="row justify-content-between text-left mt-5">
                                        <>
                                          <div className="form-group col-sm-4 flex-column d-flex">
                                            <label className="form-control-label">
                                              <b>Concession Type</b>
                                            </label>
                                            <select
                                              disabled={
                                                !PremissionChecker(
                                                  user,
                                                  "edit_student.select_concession_type"
                                                )
                                              }
                                              value={selectedConcessionId}
                                              onChange={(e) =>
                                                setSelectedConcessionId(
                                                  e.target.value
                                                )
                                              }
                                              style={{
                                                backgroundColor: "white",
                                              }}
                                              className="form-select mb-2 h-40px"
                                              aria-label="Default select example"
                                            >
                                              <option value="">
                                                Select Concession Type
                                              </option>

                                              {allconcessions.map((feetype) => (
                                                <option
                                                  key={feetype.id}
                                                  value={feetype.id}
                                                >
                                                  {feetype.title}
                                                </option>
                                              ))}
                                            </select>
                                          </div>

                                          {PremissionChecker(
                                            user,
                                            "edit_student.view_concession_allowed"
                                          ) ? (
                                            <div className="form-group col-sm-4 flex-column d-flex">
                                              <label className="form-control-label">
                                                <b>Concession Allowed </b>
                                              </label>

                                              <input
                                                disabled
                                                className="form-control h-40px"
                                                value={concessionAllowed}
                                                defaultValue={concessionAllowed}
                                                type="text"
                                              />
                                            </div>
                                          ) : (
                                            <>
                                              <small>Permission Denied </small>
                                            </>
                                          )}

                                          {PremissionChecker(
                                            user,
                                            "edit_student.can_add_fee"
                                          ) ? (
                                            <>
                                              <div className="form-group col-sm-4 flex-column d-flex">
                                                <label className="form-control-label">
                                                  <b>
                                                    Fee Type{" "}
                                                    <span
                                                      style={{ color: "red" }}
                                                    >
                                                      *
                                                    </span>{" "}
                                                  </b>
                                                </label>
                                                <select
                                                  disabled={
                                                    !PremissionChecker(
                                                      user,
                                                      "edit_student.fee_type"
                                                    )
                                                  }
                                                  id="feeselect"
                                                  // value={designation_id}
                                                  onChange={(e) => {
                                                    getRespctiveFee(
                                                      e.target.value
                                                    );
                                                  }}
                                                  className="form-select mb-2 h-40px"
                                                  aria-label="Default select example"
                                                >
                                                  <option disabled selected>
                                                    Select Fee Type{" "}
                                                  </option>
                                                  {feetypes.map((feetype) => (
                                                    <option
                                                      disabled={
                                                        feetype.disabled
                                                      }
                                                      key={feetype.id}
                                                      value={feetype.id}
                                                    >
                                                      {feetype.name}
                                                    </option>
                                                  ))}
                                                </select>
                                              </div>

                                              <div className="form-group col-sm-4 flex-column d-flex">
                                                <label className="form-control-label">
                                                  <b>Net Amount</b>
                                                </label>
                                                <input
                                                  disabled
                                                  value={netAmount}
                                                  defaultValue={netAmount}
                                                  className="form-control h-40px"
                                                  type="number"
                                                />
                                              </div>

                                              <div className="form-group col-sm-4 flex-column d-flex">
                                                <label className="form-control-label">
                                                  <b>
                                                    Concession Amount{" "}
                                                    <span
                                                      style={{ color: "red" }}
                                                    >
                                                      *
                                                    </span>
                                                  </b>
                                                </label>
                                                <input
                                                  disabled={
                                                    !PremissionChecker(
                                                      user,
                                                      "edit_student.fee_amount"
                                                    )
                                                  }
                                                  value={amount}
                                                  defaultValue={amount}
                                                  onChange={(e) => {
                                                    if (e.target.value == "") {
                                                      setisallfields(true);
                                                      seterrorcreate(
                                                        "Please Add Fee"
                                                      );
                                                    } else {
                                                      setisallfields(false);
                                                    }

                                                    if (
                                                      parseInt(e.target.value) <
                                                      0
                                                    ) {
                                                      setisallfields(true);
                                                      seterrorcreate(
                                                        "Please Enter Correct amount"
                                                      );
                                                    } else {
                                                      setisallfields(false);
                                                      seterrorcreate("");
                                                    }
                                                    setAmount(e.target.value);
                                                  }}
                                                  className="form-control h-40px"
                                                  type="number"
                                                />
                                              </div>

                                              {/* <div className="form-group col-sm-4 flex-column d-flex">
                                                <label className="form-control-label">
                                                  <b>Concession </b>
                                                </label>
                                                {applyconcessionbtn ? (
                                                  PremissionChecker(
                                                    user,
                                                    "edit_student.can_apply_concession"
                                                  ) ? (
                                                    <button
                                                      style={{
                                                        backgroundColor:
                                                          "#121291",
                                                        color: "#ffffff",
                                                        borderColor: "#121291",
                                                        width: "165px",
                                                      }}
                                                      disabled={
                                                        isallowedconcesstion
                                                      }
                                                      onClick={
                                                        applyConcessionBtn
                                                      }
                                                      className="btn btn-success  pl-4 pr-4"
                                                    >
                                                      Apply Concession
                                                    </button>
                                                  ) : (
                                                    <>
                                                      <small>
                                                        Permission denied for
                                                        apply concession
                                                      </small>
                                                    </>
                                                  )
                                                ) : (
                                                  <input
                                                    disabled
                                                    value={concessionamount}
                                                    type="number"
                                                  />
                                                )}
                                              </div> */}

                                              <div className="form-group col-sm-4 flex-column d-flex">
                                                <label className="form-control-label">
                                                  <b>Remarks </b>
                                                </label>
                                                <input
                                                  onChange={(e) => {
                                                    setremarks(e.target.value);
                                                  }}
                                                  className="form-control h-40px"
                                                  type="text"
                                                />
                                              </div>
                                            </>
                                          ) : null}
                                        </>
                                      </div>
                                      {isallfields ? (
                                        <>
                                          <Alert
                                            variant="danger"
                                            onClose={() =>
                                              setisallfields(false)
                                            }
                                            dismissible
                                          >
                                            <Alert.Heading>
                                              Oh snap! You got an error!
                                            </Alert.Heading>
                                            <p>{errorcreate}</p>
                                          </Alert>
                                        </>
                                      ) : null}
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                        }}
                                        className="btns"
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* /////define fee end section */}

                            <div
                              className="mt-5"
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              {PremissionChecker(
                                user,
                                "edit_student.can_add_fee"
                              ) ? (
                                <button
                                  disabled={parseInt(amount) < 0 ? true : false}
                                  // type="button"
                                  onClick={feeDefine}
                                  className="btn btn-success button_blue pl-4 pr-4"
                                >
                                  Add Fee
                                </button>
                              ) : (
                                <small>Permission Denied</small>
                              )}

                              <button
                                type="submit"
                                style={{ marginLeft: "15px" }}
                                className="btn btn-success button_blue btn-elevate"
                                onClick={() => handleSubmit()}
                              >
                                <span> Save </span>
                                {loading && (
                                  <span className="ml-3 spinner spinner-white"></span>
                                )}
                              </button>

                              <button
                                style={{ marginLeft: "15px" }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  show(false);
                                }}
                                className="btn btn-danger btn_width btn-elevate"
                              >
                                Cancel
                              </button>

                              <> </>
                            </div>
                          </>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <EditDefinedFee
        feeDetail={editablefee}
        onHide={seteditmodal}
        show={editmodal}
        getDefinedFees={getDefinedFees}
      />

      <Modal show={deleteModal}>
        <Modal.Header className="modal_header_style" closeButton>
          <Modal.Title
            className="Modal_Heading_font"
            id="contained-modal-title-vcenter"
          >
            Are you want to delete
          </Modal.Title>
        </Modal.Header>
        {deleteloading ? (
          <div style={{ padding: "20px" }}>
            {" "}
            <b style={{ color: "green" }}>
              {" "}
              Fee Deleting Please Wait <span className="spinner-borderd"></span>
            </b>
          </div>
        ) : (
          <Modal.Footer>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button onClick={deleteFee} className="btn button_blue">
                Delete
              </button>
              <button
                style={{ marginLeft: "10px" }}
                onClick={() => setdeleteModal(false)}
                className="btn btn-danger btn_width"
              >
                Cancel
              </button>
            </div>
          </Modal.Footer>
        )}
      </Modal>
    </div>
  );
};

export default EditStudent;
